.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blue_accent);
  padding: 0 2%;
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }
  
  .header li {
    margin-left: 3rem;
  }
  
  .header a {
    text-decoration: none;
    font-size: 1.5rem;
    color: var(--white_coordinating);
  }
  
  .header a:hover,
  .header a:active,
  .header a.active {
    color: var(--white_coordinating);
  }

  .logo {
    font-size: 45px;
    color: var(--white_coordinating);
    font-weight: bold;
  }

  .img {
    width: 75px;
    height: 45px;
  }