/* ####################################################### */
/* #### Reference: RoomReady Branding Guidelines.pdf ##### */
/* ####################################################### */
* {
  font-family: HelveticaNeue;
  box-sizing: border-box; /* With this, box sizing takes content, padding, and borders into account.  */
  margin: 0px;
  padding: 0px;
}

:root {
  /* ####################################################### */
  /* COLORS */
  /* ####################################################### */

  /*  Primary Colors
        Primary Colors are used in the
        RoomReady logos and should be
        used on all or most items
        representing RoomReady.
  */
  --blue: rgb(116, 157, 211);
  --green: rgb(140, 198, 62);

  /*  Non-Standard 
      These are not part of the official RoomReady 
      palette, but are used on web pages.
  */
  --orange: rgb(224, 128, 36);
  --orange_accent: rgb(134, 76, 22);

  /*  Coordinating Colors
        Coordinating Colors are recommended
        to help maintain a coordinated
        look across the company.
        This set of colors are standard in
        many different programs.
  */
  --grey_coordinating: rgb(110, 111, 113);
  --black_coordinating: black;
  --white_coordinating: white;

  /*  Accent Colors
        Accent Colors should be used
        as an accent or to reverse out
        the RoomReady Logo when the
        solution requires. They are dark
        enough to allow the Primary Colors
        to stand out without resorting to a
        field of black, which can feel
        oppressive.
  */
  --white_accent: rgb(241, 242, 242);
  --blue_accent: rgb(74, 89, 110);
  --green_accent: rgb(63, 90, 28);

  /* ####################################################### */
  /* LOGOS */
  /* ####################################################### */
  --logo: "logos/logo.png"; /* Logo with icon on the left and "room>ready" on the right */
  --logoWhite: "logos/logoWhite.png"; /* Same with color==white */

  --logoStacked: "logos/logoStacked.png"; /* Same as primarys with slogan underneath */
  --logoStackedWhite: "logos/logoStackedWhite.png"; /* Same with color==white */

  --logoIcon: "logos/logoIcon.png"; /* Just the icon */
  --logoIconStacked: "logos/logoIconStacked.png"; /* Same but with "room>ready" underneath the icon */

  --logoIconWhite: "logos/logoIconWhite.png";
  --logoIconStackedWhite: "logos/logoIconStackedWhite.png";

  --logoIconWatermark: "logos/logoIconWatermark.png";
}

/* ####################################################### */
/* Background color classes */
.bgcBlue {
  background-color: var(--blue);
}
.bgcGreen {
  background-color: var(--green);
}
.bgcOrange {
  background-color: var(--orange);
}

/* ####################################################### */
/* Color classes */
.cBlue {
  color: var(--blue);
}
.cGreen {
  color: var(--green);
}
.cOrange {
  color: var(--orange);
}

body {
  display: block;
  margin: 0;
  min-width: 708px;
  padding: 0 16px 0 16px;
}

/* ####################################################### */
/* Header */
.divHeader {
  border: 2px solid red;
  height: 74px;
  border-bottom: 2px solid black;
}
.divHeaderLeft {
  display: inline;
  float: left;
  height: 70px;
  border: 2px solid red;
  /* background-color: #A53030; */
}
.divHeaderRight {
  /* space in between rr-icon, caption, and other icons */
  display: inline;
  float: right;
  height: 70px;
  padding-right: 16px;
  border: 2px solid red;
  /* vertical-align: middle; */
}
.headerLogo {
  display: inline-block;
  margin-top: 0px;
  height: 70px;
  margin-left: 16px;
  border: 2px solid rgb(255, 0, 255);
}
.headerIcon {
  display: inline-block;
  height: 40px;
  margin-left: 16px;
  border: 2px solid red;
}
.headerIconWithCaption {
  display: inline-block;
  height: 40px;
}
.headerCaption {
  font-size: 54px;
}

/* ####################################################### */
/* Main - div containing EVERYTHING between header/breadcrumbs and footer */
.main {
  height: 90%;
  width: 100%;
  border: 2px solid darkgreen;
}

/* ####################################################### */
/* Left Menu */
/* menu items default to right-align */
.menu {
  display: inline-block;
  width: 200px;
  height: 80%;
  margin: 16px 16px 16px 0;
  border: 2px solid red;
  padding: 8px;
  background-color: var(--green);
}
.menuItem {
  display: block;
  line-height: 16px;
  margin: 0 0 8px auto;
}
.menuButton {
}
.menuHeadingSpanLeft {
  color: white;
  font-weight: bold;
}
.menuSpanRight {
  display: table;
  color: white;
}
.menuSpanLeft {
  color: white;
}
.menuSpanCenter {
  display: table;
  margin-left: auto;
  margin-right: auto;
  color: white;
}
/* ####################################################### */
/* Content */
.content {
  display: inline-block;
  margin: 16px 16px;
  vertical-align: top;
  border: 2px solid red;
}
/* ####################################################### */
/* footer */
.footer {
  display: inline-block;
  width: 100%;
  height: 10%;
  color: black;
  border: 2px solid rgb(47, 0, 255);
}
/* ####################################################### */
/* .copyright */
.copyright {
  text-align: center;
  color: black;
  border: 2px solid red;
}

/* ####################################################### */
/* Anchors */
a.greenBackground {
  color: white;
  font-weight: bold;
}
a.greenBackground:hover {
  color: var(--white_accent);
  font-weight: bold;
}

a.blueBackground {
  color: white;
}
a.blueBackground:hover {
  color: var(--white_accent);
}

a.whiteBackgroundGreenColor {
  color: var(--green);
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
}
a.whiteBackgroundGreenColor:hover {
  color: var(--green);
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
}

/* ####################################################### */
/* horizontal rule */
hr {
  color: var(--grey_coordinating);
}
/* ####################################################### */
/* logos */
img.logo {
  height: 100px;
}

/* ####################################################### */
/* Buttons */
button {
  background-color: var(--green);
  color: white;
  border-color: var(--green);
  border-radius: 5px;
  padding: 4px;
}

button.greenAccent {
  background-color: var(--green_accent);
  border-color: var(--green_accent);
}

button.orange {
  background-color: var(--orange);
  border-color: var(--orange);
}

button.blueAccent {
  background-color: var(--blue_accent);
  border-color: var(--blue_accent);
}

button:disabled {
  background-color: var(--grey_coordinating);
  /* color:  var(--blue_accent); */
  border-color: var(--grey_coordinating);
}

h1 {
  font-size: 2rem;
  color: var(--white_coordinating);
  font-weight: bold;
  align-self: center;
  padding: 1rem;
}
h2 {
  color: var(--white_coordinating);
  font-weight: bold;
  align-self: center;
  padding: 1rem;
}

p {
  color: var(--white_coordinating);
}

label {
  color: var(--white_coordinating);
}

.SpreadsheetGridContainer {
  width: fit-content;
  /* overflow: scroll; */
}

.SpreadsheetGrid__header {
  width: auto;
}

.SpreadsheetGrid__row {
  width: auto;
}

.SpreadsheetGrid__headCell {
  width: auto;
}

.Grid_div {
  width: auto;
  overflow: scroll;
  height: 30rem;
}

.Delete_Map {
  background-color: rgb(120, 0, 0);
  border-color: rgb(120,0,0);
}
