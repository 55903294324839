.main {
  /*margin: 0rem auto;*/
  width: 100%;
  height: 100%;
  /*max-width: 40rem;*/
  background-color: var(--blue_accent);
}

.page {
  min-height: 75rem;
  background-color: var(--blue_accent);
}
